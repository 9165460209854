import React, { useEffect, useRef, useState } from 'react';
import { List, Spin, Button, Tooltip, Typography, Image } from 'antd';
import { UserOutlined, DownloadOutlined } from '@ant-design/icons';
import ChartComponent from './ChartComponent'; // Import der ChartComponent
import Form from './Form'; // Importieren des Form-Komponenten
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import azunaLogo from '../assets/azuna-logo.svg';
import assisantLogo from '../assets/assistant_icon.svg';
import { supabase } from '../supabaseClient';




function MessageList({ messages, onLoadingChange, userDetails }) {
  const bottomListRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messagesState, setMessagesState] = useState(messages);
  const [downloadUrls, setDownloadUrls] = useState({}); // Neu: Zustand für Download-URLs

  const isImageFile = (fileName) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
  };

  const getUserInitials = (userDetails) => {
    const initialFirst = userDetails.firstName && userDetails.firstName.length > 0 ? userDetails.firstName[0] : '';
    const initialLast = userDetails.lastName && userDetails.lastName.length > 0 ? userDetails.lastName[0] : '';
    return `${initialFirst}${initialLast}`.toUpperCase();
  };

 
  



  useEffect(() => {
    const generateSignedUrls = async () => {
      let urls = {};
      // Filtere nur Nachrichten vom Typ 'file'
      const fileMessages = messages.filter(msg => msg.type === 'file');

      for (let msg of fileMessages) {
        const filePath = msg.content; // Der Pfad zur Datei, gespeichert in msg.content

        // Erstelle eine signierte URL für die Datei
        const { data, error } = await supabase
          .storage
          .from('Files') // Bucket-Name
          .createSignedUrl(filePath, 60);
        if (error) {
          console.error("Fehler beim Erstellen der signierten URL:", error);
        } else {
          // Speichere die signierte URL im 'urls'-Objekt, Schlüssel ist die Nachrichten-ID
          urls[msg.id] = data.signedUrl;
        }
      }

      // Aktualisiere den Zustand mit den generierten URLs
      setDownloadUrls(urls);
    };

    generateSignedUrls();
  }, [messages])

  // Methode zum Aktualisieren der Nachrichtendaten
  const updateMessageContent = (id, updatedContent) => {
    const updatedMessages = messagesState.map((msg) => {
      if (msg.id === id) {
        return { ...msg, content: JSON.stringify(updatedContent) };
      }
      return msg;
    });
    setMessagesState(updatedMessages);
  };


  useEffect(() => {
    if (bottomListRef.current) {
      bottomListRef.current.scrollIntoView({ behavior: 'auto' });
    }
    const lastMessage = messages[messages.length - 1];
    const isLoadingUpdate = lastMessage && lastMessage.role !== 'assistant';
    setIsLoading(isLoadingUpdate);
    onLoadingChange(isLoadingUpdate);
  }, [messages, onLoadingChange]); // Fügen Sie onLoadingChange hier hinzu


  const renderMessage = (item, index) => {

    if (!item.content) {
      // Nichts rendern, wenn der Inhalt leer ist
      return null;
    }

    if (item.type === 'chart') {
      // Parsen des Inhalts in ein JavaScript-Objekt
      let chartProps = JSON.parse(item.content);

      // Default-Werte für optionale Einstellungen setzen, falls nicht vorhanden
      chartProps.legend = chartProps.legend || "true";
      chartProps.tooltip = chartProps.tooltip || "true";
      // Nur Farben und Animationen setzen, wenn sie nicht leer sind
      if (!chartProps.axes) {
        chartProps.axes = {};
      }

      // Die vorverarbeiteten Props als JSON-String an ChartComponent übergeben
      return (
        <List.Item key={index} className="messageListItem">
          <ChartComponent jsonData={JSON.stringify(chartProps)} />
        </List.Item>
      );
    }
    else if (item.type === 'form') {
      return (
        <List.Item key={index} className="messageListItem">
          <Form
            formContent={JSON.parse(item.content)}
            messageId={item.id}
            updateMessageContent={updateMessageContent} // Hier übergeben Sie die Funktion als Prop
          />
        </List.Item>
      );
    } else if (item.type === 'file') {
      const downloadUrl = downloadUrls[item.id];
      const fileName = item.content.split('/').pop(); // Extrahiere den Dateinamen aus dem Pfad

      // Prüfe, ob es sich um ein Bild handelt
      if (isImageFile(fileName)) {
        return (
          <List.Item>
            <Image
              src={downloadUrl}
              alt={fileName}
            />
          </List.Item>
        );
      }

      // Für Nicht-Bild-Dateien
      return (
        <List.Item className='downloadItem'>
          <List.Item.Meta
            title={<Typography.Text>{fileName}</Typography.Text>}
            description="Klicke auf den Button, um die Datei herunterzuladen."
          />
          <Tooltip title="Download">
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              href={downloadUrl}
              target="_blank"
              download
            >
              Download
            </Button>
          </Tooltip>
        </List.Item>
      );
    } else if (item.role === 'system') {

      return (
        <List.Item key={index} className="messageListItem">
          <div className="messageContentSystem">
            <ReactMarkdown remarkPlugins={[gfm]}>{item.content}</ReactMarkdown>
          </div>
        </List.Item>
      );
    } else {
      return (
        <List.Item key={index} className="messageListItem">
          <div className={`messageContent${item.role === 'user' ? 'User' : item.role === 'assistant' ? 'Robot' : ''}`}>
            {/* Absendername über dem Inhalt anzeigen */}


            {/* Behalte das Icon neben dem Inhalt, wie es war */}
            {item.role === 'user'
              ? <UserOutlined className="chatIconUser" />
              : item.role === 'assistant'
                ? <img src={assisantLogo} className="chatIconAssistant" alt="Azuna Logo" />
                : null}
            <div>
              <div style={{ fontWeight: 'bold' }} className="messageSender">
                {item.role === 'user' ? getUserInitials(userDetails) : 'AssistantOS'}
              </div>
              <div className="messageContentBody">
                <ReactMarkdown
                  remarkPlugins={[gfm]}
                  components={{
                    a: ({ node, href, children, ...props }) => {
                      if (!href) {
                        return null;
                      }
                      return <a href={href} {...props} rel="noopener noreferrer" target="_blank">{children}</a>;
                    }
                  }}
                >
                  {item.content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
          {index === messages.length - 1 && <div ref={bottomListRef} />}
        </List.Item>


      );
    }

  };

  const renderNoData = () => {
    const firstName = userDetails.firstName || "Gast";  // Fallback auf "Gast", falls kein Vorname vorhanden ist
    return (
      <div className="noDataContainer">
        <p className="noDataText animatedText">Hallo {firstName}, wie kann ich heute helfen?</p>
      </div>
    );
  };


  return (
    <List
      itemLayout="horizontal"
      dataSource={messages}
      className="messageList"
      renderItem={renderMessage}
    >
      {isLoading && (
        <div className="loadingSpinnerContainer">
          <Spin size="large" />
        </div>
      )}
      {!isLoading && messages.length === 0 && renderNoData()}
    </List>
  );
}

export default MessageList;
