import React from 'react';
import { Menu, Button } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import moment from 'moment';
import azunaLogo from '../assets/azuna-logo.svg';

const ThreadList = ({ threads, onSelectThread, selectedThreadId, onCreateThread }) => {
  // Funktion zum Kategorisieren der Threads nach Datum
  const categorizedThreads = () => {
    const today = [];
    const last7Days = [];
    const last30Days = [];
    const older = [];

    threads.forEach(thread => {
      const threadDate = moment(thread.created_at); // Angenommen, 'created_at' ist dein Datumsfeld
      if (threadDate.isSame(moment(), 'day')) {
        today.push(thread);
      } else if (threadDate.isAfter(moment().subtract(7, 'days'))) {
        last7Days.push(thread);
      } else if (threadDate.isAfter(moment().subtract(30, 'days'))) {
        last30Days.push(thread);
      } else {
        older.push(thread);
      }
    });

    return { today, last7Days, last30Days, older };
  };

  const renderMenuItems = (threads) => {
    return threads.map(thread => (
      <Menu.Item key={thread.id} onClick={() => onSelectThread(thread.id)}>
        {thread.name}
      </Menu.Item>
    ));
  };

  const renderHeaderItem = (text) => (
    <Menu.Item key={text} className="dateHeader" disabled>
      {text}
    </Menu.Item>
  );

  const { today, last7Days, last30Days, older } = categorizedThreads();

  return (
    <div className="threadListContainer">
      <Button onClick={onCreateThread} block id="addThreadButton" style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img className="Azuna AssistantOS" src={azunaLogo} alt="Neuer Thread" style={{ marginRight: '8px', maxWidth: '20px' }} /> Azuna AssistantOS
        </div>
        <FormOutlined />
      </Button>
      <Menu className="threadsList" mode="inline" selectedKeys={[selectedThreadId ? selectedThreadId.toString() : '']}>
        {today.length > 0 && renderHeaderItem('Heute')}
        {renderMenuItems(today)}
        {last7Days.length > 0 && renderHeaderItem('Diese Woche')}
        {renderMenuItems(last7Days)}
        {last30Days.length > 0 && renderHeaderItem('Letzte 30 Tage')}
        {renderMenuItems(last30Days)}
        {older.length > 0 && renderHeaderItem('Älter')}
        {renderMenuItems(older)}
      </Menu>
    </div>
  );
};

export default ThreadList;
